import { initYandexMetrika } from "yandex-metrika-vue3";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.env.NODE_ENV === "development") {
    return;
  }
  const UseLocalStore = useLocalStore();
  const { cookiesSettings } = storeToRefs(UseLocalStore);
  if (!cookiesSettings.value.allCookies) {
    return;
  }
  const router = useRouter();
  const options = {
    accurateTrackBounce: true,
    clickmap: true,
    defer: false,
    ecommerce: false,
    params: [],
    userParams: {},
    trackHash: false,
    trackLinks: true,
    type: 0,
    webvisor: true,
    triggerEvent: false,
  };
  // @ts-ignore-next-line
  nuxtApp.vueApp.use(initYandexMetrika, {
    id: "97485690",
    router: router,
    env: process.env.NODE_ENV,
    options: options,
    ...options,
  });
});
